import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    ButtonSelect,
    FilterSelect,
    // ButtonSelect,
    GridCard,
    PageHeader,
} from "../../../../common/components";
import { TotalSalesData } from "./TotalSalesData";
import { HistoryData } from "../Overview/HistoryData";
import { getUserStorage } from "../../../../common/utils";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TrendingHours } from "./TrendingHours";
import { OrderTypes } from "./OrderTypes";
import { Categories } from "./Categories";
import { Products } from "./Products";
import { DayForcast } from "./DayForcast";
import { TodayForcast } from "../Overview/TodayForcast";

const ForcastHours = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(allBranchesOption.id);

    const filterItems = [
        { label: t("overview.today"), value: "Today", key: "type" },
    ];
    const [orderType, setOrderType] = useState(filterItems[0].value);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <PageHeader title={"Today’s Forecast"} />

                <Box display="flex" alignItems="flex-end" gap={4}>
                    <ButtonSelect
                        value={selectedBranch}
                        // onChange={handleBranchChange}
                        options={[allBranchesOption, ...allBranches]}
                    />

                    <FilterSelect
                        value={orderType}
                        label="Today"
                        // label={t("common.order_type")}
                        items={filterItems}
                        // onChange={({ value }) => handleFilterChange(value)}
                        icon={KeyboardArrowDownIcon}
                    />

                    <Button
                        sx={{
                            textTransform: "capitalize",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0 1px 3px hsla(220,6%,43%,0.2)",
                            border: "none",
                            "&:hover": {
                                border: "none",
                            },
                        }}
                        variant="outlined"
                    >
                        {t("common.export")}
                    </Button>
                </Box>
            </Box>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={322}
                    boxStyles={{ minHeight: "320px", height: "fit-content" }}
                    fullWidth
                >
                    {/* <DayForcast /> */}
                    <TodayForcast />
                </GridCard>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={382}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    <TotalSalesData />
                </GridCard>

                <GridCard
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    {/* <TrendingHours /> */}
                    <Categories />
                </GridCard>
            </Grid>

            {/* <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
                sx={{
                    minHeight: 400,
                    height: "fit-content",
                }}
            >
                <GridCard padding={0} height="inherit">
                    <OrderTypes />
                </GridCard>

                <GridCard padding={0} height="inherit">
                    <Categories />
                </GridCard>
            </Grid> */}

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
                sx={{
                    minHeight: 700,
                    height: "fit-content",
                }}
            >
                <GridCard padding={0} height="inherit" fullWidth>
                    <Products />
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { ForcastHours };
