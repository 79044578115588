import { useEffect, useState } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { DoughnutChart, GridCard } from "../../../../../common/components";
import { InfoCard } from "../../components/InfoCard";
import { ComposedTrendingHours } from "../../components/ComposedTrendingHours";
// import { TopProducts } from "../../components/TopProducts";
import { topProductsFakeData } from "../fake";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { constants } from "../../../../../config/constants";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { PageHeader } from "../../../../../common/components";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";
import { SalesGrowth } from "../charts/SalesGrowth";
import { AvgBasketSize } from "../charts/AvgBasketSize";
import { TotalTransaction } from "../charts/TotalTransaction";
import { PLTable } from "../pdf/PLTablePDF";
import { OrdersChart } from "../charts/OrdersChart";
import { TopProducts } from "../../components/TopProducts";

const emptyDataPie = [{ name: "Not Enough Data", value: 100, fill: "#E3E3E3" }];

const AggregatorsPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [printDate, setPrintDate] = useState("");
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const [isSingleChannelSelected, setSingleChannelSelected] = useState(false);
    const [salesData, setSalesData] = useState(null);
    const [topProducts, setTopProducts] = useState(topProductsFakeData);
    const [trendingHours, setTrendingHours] = useState(null);
    const [channelsSales, setChannelSales] = useState([]);

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setTopProducts(pdfData.top_products);
        setChannelSales(pdfData.channel_sales);
        setTrendingHours(pdfData.trending_hours);
        setSalesData(pdfData.sales_data);
        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);
        setSingleChannelSelected(pdfData.isSingleChannelSelected);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <Stack spacing={0}>
                        <PageHeader title={t("sales.aggregators")} />
                        <Typography variant="caption" color="#8a8b8b">
                            {salesData?.total_product} {t("common.product_s")}
                        </Typography>
                    </Stack>

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_sales_outline.svg"
                                label={t("sales.total_sales")}
                                value={`${
                                    numberWithCommas(salesData?.total_sales) ||
                                    0
                                } ${t("common.currency")} `}
                                tooltipLabel={t("tooltips.sales_total_sales")}
                                prevVal={12}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_profitability.svg"
                                label={t("sales.total_profitability")}
                                value={` ${
                                    numberWithCommas(
                                        salesData?.total_profitability
                                    ) || 0
                                } ${t("common.currency")}`}
                                tooltipLabel={t(
                                    "tooltips.sales_total_profitability"
                                )}
                                prevVal={-9}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_transactions_outline.svg"
                                label={t("sales.total_transactions")}
                                value={`${salesData?.total_transactions || 0}`}
                                tooltipLabel={t(
                                    "tooltips.sales_total_transactions"
                                )}
                                prevVal={19}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/products.svg"
                                label={t("sales.total_products")}
                                value={`${salesData?.total_product || 0} ${t(
                                    "common.product_s"
                                )}`}
                                tooltipLabel={t(
                                    "tooltips.sales_total_products"
                                )}
                                prevVal={29}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath=""
                                label={t("sales.delivery_sales")}
                                value={"34%"}
                                tooltipLabel={t("tooltips.delivery_sales")}
                                prevVal={-11}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={0} flexWrap="nowrap" paddingTop={10}>
                    <GridCard
                        fullWidth
                        padding={0}
                        boxStyles={{ minHeight: 500, height: "fit-content" }}
                        gutter={isArabic}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("sales.trending_hours")} /{" "}
                                {t("sales.sales_channels")}
                            </Typography>
                        </Box>

                        <Divider />

                        <Stack spacing={5} width="100%">
                            <Box
                                padding={5}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexWrap="wrap"
                                gap={4}
                                rowGap={1}
                            >
                                {!isSingleChannelSelected &&
                                    channelsSales.map((channel, index) => {
                                        const isEmptyData =
                                            channel.name ===
                                            emptyDataPie[0].name;

                                        return (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={3}
                                            >
                                                <Box
                                                    backgroundColor={
                                                        constants.pieColors[
                                                            index
                                                        ]
                                                    }
                                                    borderRadius="50%"
                                                    width="16px"
                                                    height="16px"
                                                />

                                                <Typography fontSize="14px">
                                                    {channel.name}{" "}
                                                    {`${
                                                        !isEmptyData
                                                            ? isArabic
                                                                ? `(%${channel.value})`
                                                                : `(${channel.value}%)`
                                                            : ""
                                                    }`}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                            </Box>

                            <Grid container padding={1}>
                                <Grid item xs={12}>
                                    {!!trendingHours?.length && (
                                        <ComposedTrendingHours
                                            channelsSales={channelsSales}
                                            data={trendingHours}
                                            margin={{
                                                top: 0,
                                                right: 40,
                                                left: 40,
                                                bottom: 0,
                                            }}
                                        />
                                    )}
                                </Grid>

                                {!isSingleChannelSelected && (
                                    <Grid item xs={12} sm={12}>
                                        <Box
                                            width="350px"
                                            height="100%"
                                            pr={8}
                                            position="relative"
                                            // right={isArabic ? 0 : "-44px"}
                                            left={isArabic ? "-44px" : 0}
                                            margin="auto"
                                        >
                                            <DoughnutChart
                                                data={channelsSales}
                                                pieProps={{
                                                    innerRadius: 40,
                                                    outerRadius: 95,
                                                    cy: "48%",
                                                    cx: "55%",
                                                    paddingAngle: 0,
                                                    isAnimationActive: false,
                                                    // label: data.length > 5 && false,
                                                }}
                                                legendProps={{
                                                    height: 0,
                                                    iconType: "circle",
                                                    layout: "vertical",
                                                    verticalAlign: "middle",
                                                    align: "left",
                                                    // wrapperStyle: { top: 0 },
                                                }}
                                                hideLegend
                                                hideLabels
                                                isInnerlabel
                                            />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </GridCard>
                </Grid>

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    sx={{
                        minHeight: 320,
                        height: "fit-content",
                    }}
                >
                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        padding={0}
                        height="inherit"
                    >
                        <SalesGrowth />
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        padding={0}
                        height="inherit"
                    >
                        <AvgBasketSize />
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        padding={0}
                        height="inherit"
                    >
                        <TotalTransaction />
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        boxStyles={{
                            minHeight: "466px",
                            height: "fit-content",
                        }}
                        height={372}
                    >
                        <Stack flexDirection="row" gap={20}>
                            <Stack>
                                <Typography
                                    fontWeight="bold"
                                    fontSize={20}
                                    color="#202020"
                                >
                                    Orders Rejected (7.3%)
                                </Typography>
                                <Typography fontSize={16} color="#202020">
                                    5 from the total orders
                                </Typography>
                            </Stack>

                            <Stack>
                                <Typography
                                    fontWeight="bold"
                                    fontSize={20}
                                    color="#202020"
                                >
                                    Lost sales
                                </Typography>
                                <Typography
                                    fontWeight="bold"
                                    fontSize={16}
                                    color="#202020"
                                >
                                    178 SAR
                                </Typography>
                            </Stack>
                        </Stack>
                        <OrdersChart />
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        padding={0}
                        height="inherit"
                    >
                        <PLTable withControls={false} />
                    </GridCard>

                    <GridCard height={336} padding={0} fullWidth>
                        <TopProducts data={topProducts} />
                    </GridCard>
                </Grid>
            </Stack>
        </Stack>
    );
};

export { AggregatorsPDF };
