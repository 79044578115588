const constants = {
    homepageUrl: "/",
    API: "https://console.ballurh.co/test",
    // baseURL: "http://localhost:3001",
    baseURL: "https://console.ballurh.co",
    storage: {
        token: "userAuthSession",
        user: "userAuthProfile",
        lastResyncDate: "lastResyncDate",
        language: "ballurh_lang",
        payment: "payment-in-progress",
        payment_user: "payment-add-user-in-progress",
        payment_branch: "payment-add-brach-in-progress",
        firstDate: "firstSalesDate",
    },
    currentCurrency: "SAR",
    pieColors: [
        "#9FE3B9",
        "#5BCFDE",
        "#06262D",
        "#E3E3E3",
        "#DDFBA9",
        "#837BC4",
        "#A13764",
        "#FFD9A4",
        "#F7A35C",
        "#5D7E98",
        "#C1C1C1",
        "#A3ED96",
        "#9F7CC8",
        "#DB688D",
        "#F3EFA6",
        "#549FB1",
        "#E6B9C8",
        "#F8C28E",
        "#74D2A8",
        "#3F4B6B",
        "#C8C8C8",
        "#FFCFA2",
        "#7E6BA4",
        "#B93B60",
        "#FFEBB5",
        "#FF9053",
        "#4C6E85",
        "#D2D2D2",
        "#9FE5A2",
        "#9F80C8",
        "#F38CAB",
        "#FAF5B3",
        "#62AEC2",
        "#ECBDD0",
        "#CDD69C",
        "#5294A8",
        "#FFC0D1",
        "#B2E3CC",
        "#87C9E6",
        "#273D49",
        "#EAEAEA",
        "#FFEFB0",
        "#9B8AC8",
        "#C64172",
        "#FFBD7F",
        "#FF8F5D",
        "#386B80",
    ],
    hours: {
        0: "12AM",
        1: "1AM",
        2: "2AM",
        3: "3AM",
        4: "4AM",
        5: "5AM",
        6: "6AM",
        7: "7AM",
        8: "8AM",
        9: "9AM",
        10: "10AM",
        11: "11AM",
        12: "12PM",
        13: "1PM",
        14: "2PM",
        15: "3PM",
        16: "4PM",
        17: "5PM",
        18: "6PM",
        19: "7PM",
        20: "8PM",
        21: "9PM",
        22: "10PM",
        23: "11PM",
    },
};

export const emailFormatRegExp =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;

export { constants };
