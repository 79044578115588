import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { useEffect, useState } from "react";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#999999",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const TopProducts = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(
            data.map((d, i) => ({
                ...d,
                quantity: (Math.random(1) * 100).toFixed(0),
                transaction: (Math.random(1) * 100).toFixed(0),
                isBest: i < 2,
            }))
        );
    }, [data]);

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {/* {t("overview.top_products")}     */}
                    Top & Bottom Performing Products
                </Typography>

                <Button
                    variant="outlined"
                    sx={{
                        height: 26,
                        minWidth: 83,
                        borderRadius: 2,
                        color: "#434854",
                        borderColor: "#edecef",
                        textTransform: "capitalize",
                    }}
                    onClick={() => navigate("/menu/products")}
                >
                    {t("common.view_all")}
                </Button>
            </Box>

            <Divider />

            <Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell
                                    colSpan={6}
                                    align={isArabic ? "right" : "left"}
                                >
                                    {t("common.product")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                    colSpan={4}
                                >
                                    {t("common.quantity")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    {t("common.transaction")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    {t("common.total_sales")}
                                </StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!tableData?.length &&
                                Array.from(Array(4).keys()).map((i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            colSpan={6}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                            colSpan={4}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                    </TableRow>
                                ))}

                            {tableData?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={6}
                                        align={isArabic ? "right" : "left"}
                                        sx={{
                                            color: row.isBest ? "green" : "red",
                                        }}
                                    >
                                        {row.product_name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        colSpan={4}
                                        sx={{
                                            color: row.isBest ? "green" : "red",
                                        }}
                                    >
                                        {" "}
                                        {row.quantity}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{
                                            color: row.isBest ? "green" : "red",
                                        }}
                                    >
                                        {row.transaction}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{
                                            color: row.isBest ? "green" : "red",
                                        }}
                                    >
                                        {numberWithCommas(row.total_sales)}{" "}
                                        {t("common.currency")}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    );
};

export { TopProducts };
