import { useCallback, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    List,
    Popover,
} from "@mui/material";

const MultiSelectFilter = ({ onChange, items, label, icon, filterList }) => {
    const [defaultAnchorEl, setDefaultAnchorEl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (!defaultAnchorEl) setDefaultAnchorEl(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [selected, setSelected] = useState([]);

    const handleItemClick = useCallback(
        (e, selectedItem) => {
            if (selectedItem.isChecked)
                setSelected(selected.filter((i) => i.id !== selectedItem.id));
            else setSelected([...selected, selectedItem]);

            // onChange(selectedItem);
        },
        [selected]
    );

    return (
        <Box>
            <Button
                onClick={handleClick}
                sx={{
                    minWidth: 140,
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid #ced4da",
                    "&:hover": {
                        border: "1px solid #EEEFF4",
                        backgroundColor: "white",
                    },
                    borderRadius: 4,
                    textTransform: "capitalize",
                    justifyContent: "space-between",
                }}
                variant="outlined"
            >
                {label}
            </Button>

            {/* Main */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                onClose={handleClose}
            >
                <Box sx={{ pointerEvents: "auto" }}>
                    <List sx={{ width: "150px", padding: 2 }}>
                        <FormGroup>
                            {items.map((classItem) => {
                                const isChecked = selected?.some(
                                    (filter) => filter.id === classItem.id
                                );
                                return (
                                    <FormControlLabel
                                        key={classItem}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isChecked}
                                            />
                                        }
                                        label={classItem.label}
                                        onClick={(e) =>
                                            handleItemClick(e, {
                                                ...classItem,
                                                isChecked,
                                            })
                                        }
                                    />
                                );
                            })}
                        </FormGroup>
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};

export { MultiSelectFilter };
