import { Box, Stack, Typography } from "@mui/material";
import ChatInput from "./ChatInput";
import Lottie from "lottie-react";
import animationData from "./ballurh_lottie.json";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const BallurhAssistant = () => {
    return (
        <Box sx={{ width: "100%", minHeight: "88vh" }} padding={4}>
            {" "}
            {/* Correct sx usage */}
            <Stack sx={{ height: "100%" }}>
                {" "}
                {/* Correct sx usage */}
                <Stack
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                    }}
                >
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        style={{ width: 200, height: 200 }} // Adjust width and height as needed
                    />{" "}
                    <Typography color="#5BCFDE" variant="h5">
                        Welcome to IBallurh
                    </Typography>
                    <Typography>Your Smart Chat Assistant</Typography>
                    {/* Correct Lottie usage */}
                </Stack>
                <Box display="flex" alignItems="center" gap={2}>
                    <Box sx={{ cursor: "pointer" }}>
                        <AttachFileIcon />
                    </Box>
                    <ChatInput />
                </Box>
            </Stack>
        </Box>
    );
};

export { BallurhAssistant };
