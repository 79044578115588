import { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { ChannelsSales } from "../components/ChannelsSales";
import {
    ButtonSelect,
    DoughnutChart,
    GridCard,
    GridCardFlatLabels,
    RangePicker,
} from "../../../../common/components";
import { InfoCard } from "../components/InfoCard";
import { ComposedTrendingHours } from "../components/ComposedTrendingHours";
import { TopProducts } from "../components/TopProducts";
import { services } from "../../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { getToken, getUserStorage } from "../../../../common/utils";
import { toast } from "react-toastify";
import { ExportButton } from "../../../../common/components/Export/Export";
import { SalesGrowth } from "./charts/SalesGrowth";
import { AvgBasketSize } from "./charts/AvgBasketSize";
import { TotalTransaction } from "./charts/TotalTransaction";
import { OrdersChart } from "./charts/OrdersChart";
import { PLTable } from "./charts/PLTable";
import { MultiSelectFilter } from "../../../../common/components/MultiSelectFilter/index";

const emptyDataPie = [{ name: "Not Enough Data", value: 100, fill: "#E3E3E3" }];

const SalesAggregators = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const allBrands = [
        { name: "Brand 1", id: 1 },
        { name: "Brand 2", id: 2 },
    ].map((brands) => ({
        id: brands.id,
        label: brands.name,
    }));
    const allChannelsOption = { id: "all", label: t("common.all_channels") };
    const allBrandsOption = { id: "all", label: "All Brands" };
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );
    const [selectedChannel, setSelectedChannel] = useState(
        allChannelsOption.id
    );
    const [selectedBrand, setSelectedBrand] = useState(allBrandsOption.id);

    const [salesData, setSalesData] = useState(null);
    const [topProducts, setTopProducts] = useState([]);
    const [trendingHours, setTrendingHours] = useState(null);
    const [channelsSales, setChannelSales] = useState([]);

    const isSingleChannelSelected = selectedChannel !== allChannelsOption.id;

    const [overviewPDF, setOverviewPDF] = useState(null);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const { mutate: getSalesAggregators, isLoading } = useMutation({
        mutationFn: services.getSalesAggregators,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                setSalesData(res.data.response?.totals[0]);

                const hours = response.hourly_trending;
                const refinedHours = refineSalesTrendingHours(hours);
                const trending_hours = refinedHours.trendingHours;

                const channelsSalesTotal = getTotalCount(
                    refinedHours.channelsSales
                );
                const channel_sales = !!refinedHours.channelsSales.length
                    ? refinedHours.channelsSales.map((channel, index) => ({
                          name: channel.name,
                          value:
                              Math.ceil(
                                  (
                                      channel.total_sales / channelsSalesTotal
                                  ).toFixed(2) * 1000
                              ) / 10,
                          sales: numberWithCommas(channel.total_sales),
                          fill: constants.pieColors[index],
                          // innerRadius: (index + 1) * 10,
                          outerRadius: 115 + (index + 1) * 10,
                      }))
                    : emptyDataPie;

                const top_products = response.top_five_products?.splice(0, 4);

                setTopProducts(top_products);
                setChannelSales(channel_sales);
                setTrendingHours(trending_hours);

                setOverviewPDF({
                    top_products,
                    channel_sales,
                    trending_hours,
                    sales_data: res.data.response?.totals[0],
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const refineSalesTrendingHours = (arr) => {
        const allHours = {};
        const allChannels = {};
        const channelsSales = [];
        const trendingHoursArr = [];

        for (let i = 0; i < 24; i++) {
            allHours[i] = {};
        }

        for (let hour of arr) {
            const hourNumber = +hour.hour_number;
            const hourSales = +hour.total_sales;
            const hourChannelName = hour.channel_name;

            allHours[hourNumber] = {
                ...allHours[hourNumber],
                [`${hour.channel_name}`]: hourSales,
                name: constants.hours[`${hour.hour_number}`],
                total_sales: (allHours[hourNumber] || 0) + hourSales,
            };

            if (allChannels[hourChannelName])
                allChannels[hourChannelName] =
                    allChannels[hourChannelName] + hourSales;
            else allChannels[hourChannelName] = hourSales;
        }

        Object.keys(allChannels).forEach((channel) => {
            if (allChannels[`${channel}`])
                channelsSales.push({
                    name: channel,
                    total_sales: +allChannels[`${channel}`].toFixed(2),
                });
        });

        Object.values(allHours).forEach((hour) => {
            trendingHoursArr.push(hour);
        });

        return { trendingHours: trendingHoursArr, channelsSales };
    };

    const { data } = useQuery({
        queryKey: ["allProducts"],
        queryFn: services.getMerchantChannels,
    });

    const getChannelsNames = useCallback(() => {
        return data?.data?.response.map((app) => ({
            id: app.app_id,
            label: app.name,
        }));
    }, [data?.data?.response]);

    const allChannels = getChannelsNames() || [];

    useEffect(() => {
        if (period.startDate) {
            getSalesAggregators({
                from_date: period.startDate,
                till_date: period.endDate,
                channel_id: "all",
                branch_id: "all",
            });
        }
    }, []);

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });

            getSalesAggregators({
                from_date: startDate,
                till_date: endDate,
                branch_id: selectedBranch,
                channel_id: selectedChannel,
            });
        }
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
        getSalesAggregators({
            from_date: period.startDate,
            till_date: period.endDate,
            branch_id: newSelectedBranchValue,
            channel_id: selectedChannel,
        });
    };

    const handleChannelChange = (e) => {
        const newSelectedChannelValue = e.target.value;
        setSelectedChannel(newSelectedChannelValue);
        getSalesAggregators({
            from_date: period.startDate,
            till_date: period.endDate,
            channel_id: newSelectedChannelValue,
            branch_id: selectedBranch,
        });
    };

    const handleBrandChange = (e) => {
        const newSelectedBrandValue = e.target.value;
        setSelectedBrand(newSelectedBrandValue);
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Aggregators Page",
        });
    }, []);

    const filterItems = [
        {
            value: "Class",
            label: "Class",
            key: "class",
            subItems: ["Star", "Horse", "Turtle", "Puzzle"],
        },
        {
            value: "Categories",
            label: "Categories",
            key: "category",
            subItems: ["Pizza", "Burger", "Pasta", "Sandwiches"],
        },
        // { label: 'Top products', value: 'Top products', key: 'profitablity' }
    ];

    const [selectedChannels, setSelectedChannels] = useState([]);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack spacing={0}>
                <Typography variant="h5">{t("sales.aggregators")}</Typography>
                <Typography variant="caption" color="#8a8b8b">
                    {salesData?.total_product} {t("common.product_s")}
                </Typography>
            </Stack>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" gap={3} alignItems="center">
                    <RangePicker
                        onChange={(values) => handleDatesChange(values)}
                    />

                    <Typography variant="body2">
                        {t("common.compared_to")}
                    </Typography>

                    <RangePicker
                        onChange={(values) => console.log(values)}
                        isToday
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <ButtonSelect
                        value={selectedBrand}
                        onChange={handleBrandChange}
                        options={[allBrandsOption, ...allBrands]}
                    />

                    <MultiSelectFilter
                        label={"Channel"}
                        items={[...allChannels]}
                        filterList={selectedChannels}
                        onChange={(value) => setSelectedChannels(value)}
                    />

                    <MultiSelectFilter
                        label={t("common.branch")}
                        items={[...allBranches]}
                        onChange={(value) => setSelectedChannels(value)}
                    />

                    <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            isSingleChannelSelected,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="aggregatorsPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container spacing={0} flexWrap="nowrap">
                <Grid
                    item
                    xs={2.5}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_sales_outline.svg"
                        label={t("sales.total_sales")}
                        value={`${
                            numberWithCommas(salesData?.total_sales) || 0
                        } ${t("common.currency")} `}
                        tooltipLabel={t("tooltips.sales_total_sales")}
                        prevVal={12}
                    />
                </Grid>

                <Grid
                    item
                    xs={2.5}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_profitability.svg"
                        label={t("sales.total_profitability")}
                        value={` ${
                            numberWithCommas(salesData?.total_profitability) ||
                            0
                        } ${t("common.currency")}`}
                        tooltipLabel={t("tooltips.sales_total_profitability")}
                        prevVal={-9}
                    />
                </Grid>
                <Grid
                    item
                    xs={2.5}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_transactions_outline.svg"
                        label={t("sales.total_transactions")}
                        value={`${salesData?.total_transactions || 0}`}
                        tooltipLabel={t("tooltips.sales_total_transactions")}
                        prevVal={19}
                    />
                </Grid>

                <Grid
                    item
                    xs={2.5}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/products.svg"
                        label={t("sales.total_products")}
                        value={`${salesData?.total_product || 0} ${t(
                            "common.product_s"
                        )}`}
                        tooltipLabel={t("tooltips.sales_total_products")}
                        prevVal={29}
                    />
                </Grid>

                <Grid item xs={2.5} marginRight={0}>
                    <InfoCard
                        iconPath=""
                        label={t("sales.delivery_sales")}
                        value={"34%"}
                        tooltipLabel={t("tooltips.delivery_sales")}
                        prevVal={-11}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={382}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    <SalesGrowth />
                </GridCard>

                <GridCard
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    <AvgBasketSize />
                </GridCard>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={382}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    <TotalTransaction />
                </GridCard>

                <GridCard
                    height={372}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                >
                    <Stack flexDirection="row" gap={20}>
                        <Stack>
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                Orders Rejected (7.3%)
                            </Typography>
                            <Typography fontSize={16} color="#202020">
                                5 from the total orders
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                Lost sales
                            </Typography>
                            <Typography
                                fontWeight="bold"
                                fontSize={16}
                                color="#202020"
                            >
                                178 SAR
                            </Typography>
                        </Stack>
                    </Stack>
                    <OrdersChart />
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard
                    fullWidth
                    padding={0}
                    boxStyles={{ minHeight: 500, height: "fit-content" }}
                    // gutter={isArabic}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        padding={6}
                        height={74}
                    >
                        <Typography
                            fontWeight="bold"
                            fontSize={20}
                            color="#202020"
                        >
                            {t("sales.trending_hours")} /{" "}
                            {t("sales.sales_channels")}
                        </Typography>
                    </Box>

                    <Divider />

                    <Stack spacing={5} width="100%">
                        <Box
                            padding={5}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexWrap="wrap"
                            gap={4}
                            rowGap={1}
                        >
                            {/* {!isSingleChannelSelected &&
                                channelsSales.map((channel, index) => {
                                    const isEmptyData =
                                        channel.name === emptyDataPie[0].name;

                                    return (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={3}
                                        >
                                            <Box
                                                backgroundColor={
                                                    constants.pieColors[index]
                                                }
                                                borderRadius="50%"
                                                width="16px"
                                                height="16px"
                                            />

                                            <Typography fontSize="14px">
                                                {channel.name}{" "}
                                                {`${
                                                    !isEmptyData
                                                        ? isArabic
                                                            ? `(%${channel.value})`
                                                            : `(${channel.value}%)`
                                                        : ""
                                                }`}
                                            </Typography>
                                        </Box>
                                    );
                                })} */}

                            {!isSingleChannelSelected && (
                                <GridCardFlatLabels
                                    data={channelsSales}
                                    isFlat
                                />
                            )}
                        </Box>

                        <Grid container padding={6}>
                            <Grid
                                item
                                xs={12}
                                sm={isSingleChannelSelected ? 12 : 8}
                            >
                                {!!trendingHours?.length && (
                                    <ComposedTrendingHours
                                        channelsSales={channelsSales}
                                        data={trendingHours}
                                    />
                                )}
                            </Grid>

                            {!isSingleChannelSelected && (
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="center">
                                        <Box
                                            width="350px"
                                            height="100%"
                                            pr={8}
                                            position="relative"
                                            // right={isArabic ? 0 : "-44px"}
                                            left={isArabic ? "-44px" : 0}
                                        >
                                            <DoughnutChart
                                                data={channelsSales}
                                                pieProps={{
                                                    innerRadius: 40,
                                                    outerRadius: 95,
                                                    cy: "48%",
                                                    cx: "55%",
                                                    paddingAngle: 0,
                                                    // label: data.length > 5 && false,
                                                }}
                                                legendProps={{
                                                    height: 0,
                                                    iconType: "circle",
                                                    layout: "vertical",
                                                    verticalAlign: "middle",
                                                    align: "left",
                                                    // wrapperStyle: { top: 0 },
                                                }}
                                                hideLegend
                                                hideLabels
                                                isInnerlabel
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard
                    height={336}
                    padding={0}
                    gridProps={{
                        marginRight: isArabic ? 0 : 6,
                        marginLeft: isArabic ? 6 : 0,
                    }}
                >
                    <TopProducts data={topProducts} />
                </GridCard>

                <GridCard
                    height={336}
                    padding={0}
                    gridProps={{
                        marginRight: isArabic ? 0 : 6,
                        marginLeft: isArabic ? 6 : 0,
                    }}
                >
                    <PLTable />
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { SalesAggregators };
