import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

// Fake data
const data = [
    { date: "09/3", value: 2 },
    { date: "09/4", value: 3 },
    { date: "09/5", value: 2 },
    { date: "09/6", value: 4 },
    { date: "09/7", value: 2 },
    { date: "09/8", value: 3 },
    { date: "09/9", value: 3 },
];

const OrdersChart = () => {
    return (
        <ResponsiveContainer width="100%" height={360}>
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="date" tickLine={false} />
                <YAxis
                    domain={[1, 4]}
                    ticks={[1, 2, 3, 4]} // Fixed tick values
                    tickLine={false}
                    axisLine={false}
                />
                <Tooltip />
                <Bar dataKey="value" fill="#5BCFDE" barSize={25} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export { OrdersChart };
