import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { GridCardHeader } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { AreaChart } from "../components/AreaChart";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { useNavigate } from "react-router-dom";

const ForcastRow = ({ title, iconPath, value, unit = true }) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" alignItems="center" minWidth="100%">
            <Box display="flex" alignItems="center" gap={3} flex={1}>
                <Box
                    width="42px"
                    height="42px"
                    backgroundColor="#F1F5F9"
                    borderRadius="6px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={iconPath} alt="images" />
                </Box>

                <Typography fontSize={18} color="#202020">
                    {title}
                </Typography>
            </Box>

            <Typography fontSize={22} color="#202020" fontWeight="bold">
                {numberWithCommas(value)}
                {unit && t("common.currency")}
            </Typography>
        </Box>
    );
};

const TodayForcast = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Box display="flex" alignItems="baseline" gap={3}>
                    <GridCardHeader title="Today’s Forecast" />

                    <Typography color="#202020" fontSize="15px">
                        Last updated 02:13 PM
                    </Typography>
                </Box>
                {/* <GridCardHeader title={t("forcast.total_sales_data")} /> */}

                {/* <Button
                    variant="contained"
                    sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#5BCFDE",
                        borderRadius: "8px",
                        "&:hover": { backgroundColor: "#5BCFDE" },
                    }}
                    onClick={() => navigate("/forcast/hours")}
                >
                    Next Hours
                </Button> */}
            </Box>

            <Divider />

            <Box display="flex" width="100%" p={6} gap={10}>
                <Stack width="50%">
                    <ForcastRow
                        title="Sales Forecast"
                        iconPath="/ic_sales_outline.svg"
                        value={32098000}
                    />

                    <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                    <ForcastRow
                        title="Total Profitability"
                        iconPath="/ic_profitability.svg"
                        value={89240000}
                    />

                    <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                    <ForcastRow
                        title="Avg Basket Size Value"
                        iconPath="/ic_basket.svg"
                        value={3219200}
                    />
                </Stack>

                <Stack width="50%">
                    <ForcastRow
                        title="Customers"
                        iconPath="/ic_customers_many.svg"
                        value={74900}
                        unit={false}
                    />

                    <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                    <ForcastRow
                        title="Products"
                        iconPath="/products.svg"
                        value={900}
                        unit={false}
                    />

                    <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                    <ForcastRow
                        title="Transactions"
                        iconPath="/ic_transactions_outline.svg"
                        value={120900}
                        unit={false}
                    />
                </Stack>
            </Box>
        </Stack>
    );
};

export { TodayForcast };
