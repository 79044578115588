import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    input: {
        width: "100%",
        margin: "10px 0",
        borderRadius: "20px",
        backgroundColor: "#f5f5f5",
        "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
            "& fieldset": {
                borderColor: "#ccc", // Default border color
            },
            "&:hover fieldset": {
                borderColor: "#5BCFDE", // Border color on hover
            },
            "&.Mui-focused fieldset": {
                borderColor: "#5BCFDE", // Border color when focused
            },
            "& .MuiOutlinedInput-input": {
                padding: "10px", // Adjust padding to reduce height
                fontSize: "14px", // Adjust font size to reduce height further if needed
            },
        },
        position: "sticky",
        bottom: 0,
    },
});

const ChatInput = () => {
    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            placeholder="Type a message..."
            className={classes.input}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton edge="end">
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default ChatInput;
