import {
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { VerticalBarChart } from "../../../Overview/components/VerticalBarChart";
import { GridCardFlatLabels } from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import VerticalStackedBarChart from "./StackedVerticalChart";
import { ArrowDownward } from "@mui/icons-material";

// Fake data with stacked values
const fakeData = [
    {
        name: "Al-Narjis",
        Hungerstation: 65,
        Ninja: 40,
        Mrsool: 30,
        Jahez: 15,
        noon: 25,
        theChefz: 20,
    },
    {
        name: "Al-Aqiq",
        Hungerstation: 45,
        Ninja: 35,
        Mrsool: 25,
        Jahez: 20,
        noon: 15,
        theChefz: 10,
    },
    {
        name: "Al-Arid",
        Hungerstation: 70,
        Ninja: 35,
        Mrsool: 25,
        Jahez: 40,
        noon: 30,
        theChefz: 20,
    },
    {
        name: "Al-Rayan",
        Hungerstation: 75,
        Ninja: 45,
        Mrsool: 35,
        Jahez: 30,
        noon: 20,
        theChefz: 25,
    },
    {
        name: "Al-Malqa",
        Hungerstation: 55,
        Ninja: 30,
        Mrsool: 20,
        Jahez: 25,
        noon: 30,
        theChefz: 15,
    },
];

const TotalTransaction = ({ data = fakeData }) => {
    const { t } = useTranslation();

    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        const categoryTotal = getTotalCount(data);
        const category_data = data.map((order, index) => {
            const orderData = {
                name: order.category_name,
                records: data.map((order, index) => ({
                    name: order.category_name,
                    fill: constants.pieColors[index],
                })),
                value: Math.ceil(
                    ((+order.total_sales / categoryTotal).toFixed(4) * 1000) /
                        10
                ),
            };

            // biome-ignore lint/complexity/noForEach: <explanation>
            data.forEach((order) => {
                orderData[`${order.category_name}`] = Math.ceil(
                    ((+order.total_sales / categoryTotal).toFixed(4) * 1000) /
                        10
                );
            });

            return orderData;
        });

        setCategoryData(category_data);
    }, []);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const labelsData = useMemo(
        () =>
            Object.keys(fakeData[0])
                .filter((k) => k !== "name")
                .map((labelName, index) => ({
                    name: labelName,
                    fill: constants.pieColors[index],
                })),
        []
    );

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Stack gap={4} flexDirection="row" alignItems="center">
                    <Typography fontWeight="bold" fontSize={20} color="#202020">
                        {t("overview.total_transactions")}
                    </Typography>

                    <Stack gap={1} flexDirection="row">
                        <Box
                            backgroundColor="#f2f2f2"
                            padding={1}
                            borderRadius="50%"
                            width="26px"
                            height="26px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ArrowDownward sx={{ fontSize: 18 }} />
                        </Box>
                        <Box paddingY={1} color={"red"}>
                            {8}%
                        </Box>
                    </Stack>
                </Stack>
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels
                    data={labelsData}
                    isFlat
                    withPercentage={false}
                />

                <Box width="100%" height="300px" px={6}>
                    <VerticalStackedBarChart data={fakeData} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { TotalTransaction };
